import * as React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './graphCard.css';
import { IonCard } from '@ionic/react';
import { colors } from '../CustomGraphComponents';

type KinematicsGraphProps = {
  data: any;
  title: string;
  subtitle: string;
  annotations_Left: any;
  annotations_Right: any;
  strokeWidth: number;
};

export const KinematicsGraph = ({
  data,
  title,
  subtitle,
  annotations_Left,
  annotations_Right,
  strokeWidth,
}: KinematicsGraphProps) => {
  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      offsetX: -10,
    },
    xaxis: {
      type: 'numeric',
      labels: {
        style: {
          fontSize: '8px',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        style: {
          fontSize: '8px',
        },
      },
    },
    title: {
      text: title,
      align: 'center',
      margin: 0,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        color: '#2066FA',
      },
    },
    subtitle: {
      text: subtitle,
      align: 'center',
      margin: -10,
      style: {
        fontSize: '8px',
        fontFamily: 'Montserrat',
      },
    },

    stroke: {
      width: strokeWidth,
      dashArray: [0],
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      markers: {
        width: 8,
        height: 8,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    annotations: {
      xaxis: [
        {
          x: annotations_Left[1],
          borderColor: '#c2c2c2',
          label: {
            text: 'TO',
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: annotations_Left[2],
          borderColor: '#c2c2c2',
          label: {
            text: 'MSw',
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: annotations_Left[5],
          borderColor: '#c2c2c2',
          label: {
            text: 'TSw',
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: annotations_Left[3],
          borderColor: '#c2c2c2',
          label: {
            text: 'IC',
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: annotations_Left[4],
          borderColor: '#c2c2c2',
          label: {
            text: 'MSt',
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: annotations_Left[0],
          borderColor: '#c2c2c2',
          label: {
            text: 'HO',
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },

        {
          x: annotations_Right[1],
          borderColor: '#00E396',
          label: {
            text: 'TO',
            borderColor: 'transparent',
            position: 'top',
            orientation: 'horizontal',
            offsetY: -8,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: annotations_Right[2],
          borderColor: '#00E396',
          label: {
            text: 'MSw',
            borderColor: 'transparent',
            position: 'top',
            orientation: 'horizontal',
            offsetY: -8,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: annotations_Right[5],
          borderColor: '#00E396',
          label: {
            text: 'TSw',
            borderColor: 'transparent',
            position: 'top',
            orientation: 'horizontal',
            offsetY: -8,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: annotations_Right[3],
          borderColor: '#00E396',
          label: {
            text: 'IC',
            borderColor: 'transparent',
            position: 'top',
            orientation: 'horizontal',
            offsetY: -8,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: annotations_Right[4],
          borderColor: '#00E396',
          label: {
            text: 'MSt',
            borderColor: 'transparent',
            position: 'top',
            orientation: 'horizontal',
            offsetY: -8,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: annotations_Right[0],
          borderColor: '#00E396',
          label: {
            text: 'HO',
            borderColor: 'transparent',
            position: 'top',
            orientation: 'horizontal',
            offsetY: -8,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
      ],
    },
    markers: {
      size: [0, 0, 2],
      strokeWidth: 0,
      showNullDataPoints: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div className='kinematicsGraph_Wrapper'>
      <Chart options={options} series={data} type='line' />
    </div>
  );
};
