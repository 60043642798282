export const colors = [
  '#ff0000',
  '#00ff00',
  '#0000ff',
  '#ffff00',
  '#ff00ff',
  '#00ffff',
  '#800000',
  '#008000',
  '#000080',
  '#808000',
  '#800080',
  '#008080',
  '#ff6666',
  '#66ff66',
  '#6666ff',
  '#ffff66',
  '#ff66ff',
  '#66ffff',
  '#ff9933',
  '#33ff99',
  '#9933ff',
  '#99ff33',
  '#ff33cc',
  '#33ccff',
  '#cc33ff',
  '#ccff33',
  '#ff3399',
  '#3399ff',
  '#99ff99',
  '#9999ff',
  '#ff9999',
  '#9999ff',
];

const styles = getComputedStyle(document.documentElement);
export const colorSS = String(styles.getPropertyValue('--ion-color-primary700')).trim(); // Blue
export const colorST = String(styles.getPropertyValue('--ion-color-teal800')).trim(); // Lightblue'#D97706'; // Orange
export const colorSC = String(styles.getPropertyValue('--ion-color-light-blue500')).trim(); // Medium blue"#FF0000"; // red

export const colorCS = String(styles.getPropertyValue('--ion-color-error700')).trim(); // red
export const colorCT = String(styles.getPropertyValue('--ion-color-warning600')).trim(); // orange
export const colorCC = String(styles.getPropertyValue('--ion-color-amber400')).trim(); // green

export const colorTS = String(styles.getPropertyValue('--ion-color-emerald900')).trim();
export const colorTT = String(styles.getPropertyValue('--ion-color-success600')).trim();
export const colorTC = String(styles.getPropertyValue('--ion-color-emerald400')).trim();

export const colorLeft = String(styles.getPropertyValue('--ion-color-primary700')).trim();
export const colorRight = String(styles.getPropertyValue('--ion-color-success600')).trim();

export const variabilityAll_Left = String(styles.getPropertyValue('--ion-color-light-blue500')).trim();
export const variabilitySelected_Left = String(styles.getPropertyValue('--ion-color-teal800')).trim();
export const variabilityMean_Left = String(styles.getPropertyValue('--ion-color-primary700')).trim();

export const variabilityAll_Right = String(styles.getPropertyValue('--ion-color-secondary600')).trim();
export const variabilitySelected_Right = String(styles.getPropertyValue('--ion-color-success600')).trim();
export const variabilityMean_Right = String(styles.getPropertyValue('--ion-color-emerald900')).trim();
