import * as React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './graphCard.css';
import { IonCard } from '@ionic/react';
import { colors } from '../CustomGraphComponents';

type FunctionalMovementGraphProps = {
  data: any;
  title: string;
  subtitle: string;
  strokeWidth: number;
};

export const FunctionalMovementKinematicsGraph = ({
  data,
  title,
  subtitle,
  strokeWidth,
}: FunctionalMovementGraphProps) => {
  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      offsetX: -10,
    },
    xaxis: {
      type: 'numeric',
      labels: {
        style: {
          fontSize: '8px',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        style: {
          fontSize: '8px',
        },
      },
    },
    title: {
      text: title,
      align: 'center',
      margin: 0,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        color: '#2066FA',
      },
    },
    subtitle: {
      text: subtitle,
      align: 'center',
      margin: -10,
      style: {
        fontSize: '8px',
        fontFamily: 'Montserrat',
      },
    },

    stroke: {
      width: strokeWidth,
      dashArray: [0],
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      markers: {
        width: 8,
        height: 8,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    markers: {
      size: [0, 0, 2],
      strokeWidth: 0,
      showNullDataPoints: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div className='kinematicsGraph_Wrapper'>
      <Chart options={options} series={data} type='line' />
    </div>
  );
};
