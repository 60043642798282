/** Walk kinematics report tab */
import {
  IonCard,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import { pricetagsOutline } from 'ionicons/icons';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import WalkEnd from '../../../assets/avatars/walk/kinematics/End.png';
import HeelOff from '../../../assets/avatars/walk/kinematics/HeelOff.png';
import InitialContact from '../../../assets/avatars/walk/kinematics/InitialContact.png';
import Midstance from '../../../assets/avatars/walk/kinematics/Midstance.png';
import Midswing from '../../../assets/avatars/walk/kinematics/Midswing.png';
import ToeOff from '../../../assets/avatars/walk/kinematics/ToeOff.png';
import { Report, WalkReportData } from '../../../model';
import { AnkleGraphs, HipGraphs, KneeGraphs, PelvisGraphs } from '../components/KinematicsGraph';
import { RomTable, SpatioTemporalTable } from '../components/KinematicsTable';
import { GraphReportSection, ReportTableSection } from '../components/ReportSection';
import { KinematicsGraph } from '../../ReportV2/components/Graphs';
import { getLeftRightSerieData } from '../../ReportV2/components/DataFunctions';
import { useMemo } from 'react';
import { DashboardCard } from '../components/dashboardCard';
import { toMatrix, transpose } from '../../../hooks/useReports';

const Info: React.FC = () => {
  return <p>Under construction - Coming soon!</p>;
};

/* Avatars in the order as shown on the x-axis */
const avatars = [HeelOff, ToeOff, Midswing, InitialContact, Midstance, WalkEnd];

type WalkKinematicsReportProps = RouteComponentProps & {
  report: Report;
};

/** Walk kinematics report tab
 * @param report - The full walk report info and data
 */
export const WalkKinematicsReportTab = ({ report }: WalkKinematicsReportProps) => {
  const data = report.data as WalkReportData | undefined;
  const romData: number[][] = transpose(data?.rom);
  const tableData: number[][] = toMatrix(data?.spatioTemporal);

  const annotations_Left = data?.pointsOfInterests.col0;
  const annotations_Right = data?.pointsOfInterests.col1;

  const seriesPelvis_Sagittal = useMemo(
    () => getLeftRightSerieData(data?.kinematicData.col0, data?.kinematicData.col7),
    [data],
  );

  const seriesPelvis_Coronal = useMemo(
    () => getLeftRightSerieData(data?.kinematicData.col8, data?.kinematicData.col9),
    [data],
  );
  const seriesPelvis_Transversal = useMemo(
    () => getLeftRightSerieData(data?.kinematicData.col16, data?.kinematicData.col17),
    [data],
  );

  const seriesHip_Sagittal = useMemo(
    () => getLeftRightSerieData(data?.kinematicData.col1, data?.kinematicData.col2),
    [data],
  );
  const seriesHip_Coronal = useMemo(
    () => getLeftRightSerieData(data?.kinematicData.col10, data?.kinematicData.col11),
    [data],
  );
  const seriesHip_Transversal = useMemo(
    () => getLeftRightSerieData(data?.kinematicData.col18, data?.kinematicData.col19),
    [data],
  );

  const seriesKnee_Sagittal = useMemo(
    () => getLeftRightSerieData(data?.kinematicData.col3, data?.kinematicData.col4),
    [data],
  );
  const seriesKnee_Coronal = useMemo(
    () => getLeftRightSerieData(data?.kinematicData.col12, data?.kinematicData.col13),
    [data],
  );
  const seriesKnee_Transversal = useMemo(
    () => getLeftRightSerieData(data?.kinematicData.col20, data?.kinematicData.col21),
    [data],
  );

  const seriesAnkle_Sagittal = useMemo(
    () => getLeftRightSerieData(data?.kinematicData.col5, data?.kinematicData.col6),
    [data],
  );
  const seriesAnkle_Coronal = useMemo(
    () => getLeftRightSerieData(data?.kinematicData.col14, data?.kinematicData.col15),
    [data],
  );
  const seriesAnkle_Transversal = useMemo(
    () => getLeftRightSerieData(data?.kinematicData.col22, data?.kinematicData.col23),
    [data],
  );

  if (!data) {
    return <div>No kinematics data present.</div>;
  }

  return (
    <IonPage>
      <IonContent className='ion-padding' color='light'>
        {data && (
          <IonGrid>
            {/* Pelvis */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='ORYX_GraphCard'>
                  <IonRow className='ion-justify-content-center'>
                    <IonText className='ORYX_GraphTitle'>Pelvis</IonText>
                  </IonRow>
                  <IonRow>
                    <IonCol size='4' className='ion-no-padding'>
                      <KinematicsGraph
                        data={seriesPelvis_Sagittal}
                        title={'Sagittal'}
                        subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
                        annotations_Left={annotations_Left}
                        annotations_Right={annotations_Right}
                        strokeWidth={2}
                      />
                    </IonCol>
                    <IonCol size='4' className='ion-no-padding'>
                      <KinematicsGraph
                        data={seriesPelvis_Coronal}
                        title={'Frontal'}
                        subtitle={'Drop (-) / Hike (+)'}
                        annotations_Left={annotations_Left}
                        annotations_Right={annotations_Right}
                        strokeWidth={2}
                      />
                    </IonCol>
                    <IonCol size='4' className='ion-no-padding'>
                      <KinematicsGraph
                        data={seriesPelvis_Transversal}
                        title={'Transversal'}
                        subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
                        annotations_Left={annotations_Left}
                        annotations_Right={annotations_Right}
                        strokeWidth={2}
                      />
                    </IonCol>
                  </IonRow>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Hip */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='ORYX_GraphCard'>
                  <IonRow className='ion-justify-content-center'>
                    <IonText className='ORYX_GraphTitle'>Hip</IonText>
                  </IonRow>
                  <IonRow>
                    <IonCol size='4' className='ion-no-padding'>
                      <KinematicsGraph
                        data={seriesHip_Sagittal}
                        title={'Sagittal'}
                        subtitle={'Extension (-) / Flexion (+)'}
                        annotations_Left={annotations_Left}
                        annotations_Right={annotations_Right}
                        strokeWidth={2}
                      />
                    </IonCol>
                    <IonCol size='4' className='ion-no-padding'>
                      <KinematicsGraph
                        data={seriesHip_Coronal}
                        title={'Frontal'}
                        subtitle={'Abduction (-) / Adduction (+)'}
                        annotations_Left={annotations_Left}
                        annotations_Right={annotations_Right}
                        strokeWidth={2}
                      />
                    </IonCol>
                    <IonCol size='4' className='ion-no-padding'>
                      <KinematicsGraph
                        data={seriesHip_Transversal}
                        title={'Transversal'}
                        subtitle={'External Rotation (-) / Internal Rotation (+)'}
                        annotations_Left={annotations_Left}
                        annotations_Right={annotations_Right}
                        strokeWidth={2}
                      />
                    </IonCol>
                  </IonRow>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Knee */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='ORYX_GraphCard'>
                  <IonRow className='ion-justify-content-center'>
                    <IonText className='ORYX_GraphTitle'>Knee</IonText>
                  </IonRow>
                  <IonRow>
                    <IonCol size='4' className='ion-no-padding'>
                      <KinematicsGraph
                        data={seriesKnee_Sagittal}
                        title={'Sagittal'}
                        subtitle={'Extension (-) / Flexion (+)'}
                        annotations_Left={annotations_Left}
                        annotations_Right={annotations_Right}
                        strokeWidth={2}
                      />
                    </IonCol>
                    <IonCol size='4' className='ion-no-padding'>
                      <KinematicsGraph
                        data={seriesKnee_Coronal}
                        title={'Frontal'}
                        subtitle={'Valgus (-) / Varus (+)'}
                        annotations_Left={annotations_Left}
                        annotations_Right={annotations_Right}
                        strokeWidth={2}
                      />
                    </IonCol>
                    <IonCol size='4' className='ion-no-padding'>
                      <KinematicsGraph
                        data={seriesKnee_Transversal}
                        title={'Transversal'}
                        subtitle={'External Rotation (-) / Internal Rotation (+)'}
                        annotations_Left={annotations_Left}
                        annotations_Right={annotations_Right}
                        strokeWidth={2}
                      />
                    </IonCol>
                  </IonRow>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Ankle */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='ORYX_GraphCard'>
                  <IonRow className='ion-justify-content-center'>
                    <IonText className='ORYX_GraphTitle'>Ankle</IonText>
                  </IonRow>
                  <IonRow>
                    <IonCol size='4' className='ion-no-padding'>
                      <KinematicsGraph
                        data={seriesAnkle_Sagittal}
                        title={'Sagittal'}
                        subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
                        annotations_Left={annotations_Left}
                        annotations_Right={annotations_Right}
                        strokeWidth={2}
                      />
                    </IonCol>
                    <IonCol size='4' className='ion-no-padding'>
                      <KinematicsGraph
                        data={seriesAnkle_Coronal}
                        title={'Frontal'}
                        subtitle={'Eversion (-) / Inversion (+)'}
                        annotations_Left={annotations_Left}
                        annotations_Right={annotations_Right}
                        strokeWidth={2}
                      />
                    </IonCol>
                    <IonCol size='4' className='ion-no-padding'>
                      <KinematicsGraph
                        data={seriesAnkle_Transversal}
                        title={'Transversal'}
                        subtitle={'External Rotation (-) / Internal Rotation (+)'}
                        annotations_Left={annotations_Left}
                        annotations_Right={annotations_Right}
                        strokeWidth={2}
                      />
                    </IonCol>
                  </IonRow>
                </IonCard>
              </IonCol>
            </IonRow>

            <IonRow class='ion-no-padding'>
              <IonCol size='6'>
                <IonCard className='ORYX_GraphCard'>
                  <IonRow className='ion-justify-content-center'>
                    <IonText className='ORYX_GraphTitle'>ROM Table</IonText>
                  </IonRow>
                  <IonRow className='ion-padding-vertical full_height ion-align-items-center'>
                    <IonCol size='12' className='ion-no-padding'>
                      <table className='ORYX_Table_noMargin'>
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            <th>Left</th>
                            <th>Right</th>
                            <th>Symmetry</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='ORYX_Table_Head'>
                            <td> Pelvis</td>
                            <td>{romData[0][0].toFixed(1) + '°'}</td>
                            <td>{romData[0][1].toFixed(1) + '°'}</td>
                            <td>{romData[0][2].toFixed(0) + '%'}</td>
                          </tr>
                          <tr className='ORYX_Table_Head'>
                            <td> Hip</td>
                            <td>{romData[1][0].toFixed(1) + '°'}</td>
                            <td>{romData[1][1].toFixed(1) + '°'}</td>
                            <td>{romData[1][2].toFixed(0) + '%'}</td>
                          </tr>
                          <tr>
                            <td className='ORYX_Table_Indent'>Flexion</td>
                            <td>{romData[2][0].toFixed(1) + '°'}</td>
                            <td>{romData[2][1].toFixed(1) + '°'}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td className='ORYX_Table_Indent'>Extension</td>
                            <td>{romData[3][0].toFixed(1) + '°'}</td>
                            <td>{romData[3][1].toFixed(1) + '°'}</td>
                            <td></td>
                          </tr>
                          <tr className='ORYX_Table_Head'>
                            <td>Knee</td>
                            <td>{romData[4][0].toFixed(1) + '°'}</td>
                            <td>{romData[4][1].toFixed(1) + '°'}</td>
                            <td>{romData[4][2].toFixed(0) + '%'}</td>
                          </tr>
                          <tr>
                            <td className='ORYX_Table_Indent'> Stance flexion</td>
                            <td>{romData[5][0].toFixed(1) + '°'}</td>
                            <td>{romData[5][1].toFixed(1) + '°'}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td className='ORYX_Table_Indent'>Varus Valgus</td>
                            <td>{romData[6][0].toFixed(1) + '°'}</td>
                            <td>{romData[6][1].toFixed(1) + '°'}</td>
                            <td></td>
                          </tr>
                          <tr className='ORYX_Table_Head'>
                            <td>Ankle</td>
                            <td>{romData[7][0].toFixed(1) + '°'}</td>
                            <td>{romData[7][1].toFixed(1) + '°'}</td>
                            <td>{romData[7][2].toFixed(0) + '%'}</td>
                          </tr>
                          <tr>
                            <td className='ORYX_Table_Indent'>Dorsal flexion</td>
                            <td>{romData[8][0].toFixed(1) + '°'}</td>
                            <td>{romData[8][1].toFixed(1) + '°'}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td className='ORYX_Table_Indent'>Plantar flexion</td>
                            <td>{romData[9][0].toFixed(1) + '°'}</td>
                            <td>{romData[9][1].toFixed(1) + '°'}</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </IonCol>
                  </IonRow>
                </IonCard>
              </IonCol>

              <IonCol size='6'>
                <IonCard className='ORYX_GraphCard'>
                  <IonRow className='ion-justify-content-center'>
                    <IonText className='ORYX_GraphTitle'>Spatiotemporal Table</IonText>
                  </IonRow>
                  <IonRow className='ion-padding-vertical full_height ion-align-items-center'>
                    <IonCol size='12' className='ion-no-padding'>
                      <table className='ORYX_Table_noMargin'>
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            <th>Left</th>
                            <th>Right</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='ORYX_Table_Head'>
                            <td>Stance phase (Percentage of stride time)</td>
                            <td>{tableData[3][1].toFixed(0) + ' %'}</td>
                            <td>{tableData[4][1].toFixed(0) + ' %'}</td>
                          </tr>
                          <tr>
                            <td className='ORYX_Table_Indent'>Stance phase (seconds)</td>
                            <td>{tableData[0][1].toFixed(2) + ' s'}</td>
                            <td>{tableData[1][1].toFixed(2) + ' s'}</td>
                          </tr>
                          <tr className='ORYX_Table_Head'>
                            <td>Swing phase (Percentage of stride time)</td>
                            <td>{tableData[3][2].toFixed(0) + ' %'}</td>
                            <td>{tableData[4][2].toFixed(0) + ' %'}</td>
                          </tr>
                          <tr>
                            <td className='ORYX_Table_Indent'>Swing phase (seconds)</td>
                            <td>{tableData[0][2].toFixed(2) + ' s'}</td>
                            <td>{tableData[1][2].toFixed(2) + ' s'}</td>
                          </tr>
                        </tbody>
                      </table>
                    </IonCol>
                  </IonRow>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};
