/** Hook for fuzzy searching in a list (of objects) using Fuse.js */
import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';

type UseSearchHook<T> = {
  onSearch: (e: any) => void;
  query: string;
  searchResult: T[];
};

/** Fuzzy search hook
 * @example
 * const options = {
 *   includeScore: true,
 *   keys: ['filename', 'tags'],
 * };
 * const list = Object.values(reportDict);
 * const {onSearch, query, searchResult} = useSearch(options, list);
 */
export const useSearch = <T>(options: Fuse.IFuseOptions<T>, list: T[]): UseSearchHook<T> => {
  const [query, setQuery] = useState('');
  const [searchResult, setResult] = useState<T[]>([]);

  const onSearch = (e: any) => {
    if (typeof e === 'string') {
      setQuery(e);
    } else if (typeof e === 'number') {
      setQuery(e.toString());
    } else {
      setQuery(e.detail.value);
    }
  };

  useEffect(() => {
    const search = (query: string): T[] => {
      const fuseOptions = {
        threshold: 0.4,
        includeMatches: true,
        ...options,
      };
      const fuse = new Fuse<T>(list, fuseOptions);
      const fuseResult = fuse.search(query);
      return fuseResult.map((res) => res.item);
    };

    setResult(query ? search(query) : list);
  }, [query, list]);

  return {
    onSearch: onSearch,
    query: query,
    searchResult: searchResult,
  };
};
